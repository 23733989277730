import {
  PROFILE,
  TRANSLATION,
  WITHDRAWAGAIN,
  APP_INFO,
  PAYMENT_SETTINGS
} from "../actions/actionTypes";

export const initialState = {
  profile: {},
  translation: {},
  getWithdrawAgain: false,
  appInfo: {},
  pymentSettings:{}
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case TRANSLATION:
      return {
        ...state,
        translation: action.payload,
      };
    case WITHDRAWAGAIN:
      return {
        ...state,
        getWithdrawAgain: action.payload,
      };
    case APP_INFO:
      return {
        ...state,
        appInfo: action.payload,
      };
    case PAYMENT_SETTINGS:
      return {
        ...state,
        pymentSettings: action.payload,
      };
    default:
      return state;
  }
};

export default globalReducer;
