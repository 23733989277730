import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Form, Input, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { REQUESTS } from "../../../api/requests";
import TEXTS from "../../../config/texts";
import Message from "../../../components/message";

import classes from "../style/playListDrawer.module.scss";

export default function DeviceDrawer({
  visible,
  onClose,
  currentDevice,
  getDevices,
}) {
  const translation = useSelector((state) => state.globalState.translation);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [packages, setPackages] = useState(null);

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const getPackages = () => {
    REQUESTS.PACKAGES((data) => setPackages(data.rows));
  };

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      activation_id: currentDevice.id,
      package_id: values.package_id,
    };

    function callback() {
      setLoading(false);
      onClose();
      getDevices();
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.DEVICE.PLAYLIST.EDIT(body, callback, errorCallback);
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  useEffect(() => {
    if (currentDevice) {
      if (currentDevice) {
        form.setFields([
          { name: "mac", value: currentDevice.mac },
          { name: "playlist_name", value: currentDevice?.playlist?.name },
        ]);
      }
    } else {
      form.resetFields();
    }
  }, [currentDevice, visible]);

  const onValuesChange = () => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  useEffect(() => {
    removeMessage();
    if (!visible) {
      removeMessage();
      setLoading(false);
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    getPackages();
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <>
      <Drawer
      width={windowWidth > 600 ? 600 : "75vw"}
      title={translation["Device"] || TEXTS["Device"]}
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <Form
          form={form}
          layout={"vertical"}
          name="playlist"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="mac"
            label={translation["Mac address"] || TEXTS["Mac address"]}
          >
            <Input disabled={true} />
          </Form.Item>
       
          <Form.Item
            label={translation['Package'] || TEXTS["Package"]}
            name="package_id"
          >
            <Select
              className={classes["packages-select"]}
              placeholder={translation["Select package"] || TEXTS["Select package"]}
            >
              {packages &&
                packages.map((item) => {
                  const { name, activation_needed, id } = item;
                  return (
                    <Select.Option
                      key={id}
                      value={id}
                    >{` ${name} ${activation_needed}`}</Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Message message={message} />

          <Form.Item className={classes["current-device-button"]}>
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
              icon={<CheckOutlined />}
            >
              {translation["Save"] || TEXTS["Save"]}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
