import { Modal } from 'antd';

const confirm = (title, actions, okText, cancelText) => {
    Modal.confirm({
        title,
        icon: null,
        okText,
        cancelText,
        okButtonProps: {
            type: "danger",
        },
        onOk: () => actions(),
    });
};

export { confirm };
