import { useSelector } from "react-redux";
import IMAGES from "../../config/images";
import TEXTS from "../../config/texts";

/**
 * Payment Success Page Component is used to show success page when payment is done. It is used in PaymentPage.jsx. Redirected here from paypal or stripe.
 * @returns
 */

export default function PaymentSuccessPage({}) {
    const translation = useSelector((store) => store.globalState.translation);

    return (
        <div
            style={{
                width: "100%",
                height: "calc(100vh - 136px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img src={IMAGES.SUCCESS} />

            <h2 style={{ marginTop: 30, color: "#2c93f4", width: 500, textAlign: "center" }}>
                {translation["Your payment was done successfully"] ||
                    TEXTS["Your payment was done successfully"]}
            </h2>
        </div>
    );
}
