import React, { useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Table } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import Pages from '../../../components/Pages';
import TableButtons from '../../../components/TableButtons';
import { getColumnSearchProps } from "../../../config/config";
import { parseFullDate } from '../../../config/formats'

import { confirm } from "../../../config/confirm";
import ICONS from "../../../config/icons";
import { REQUESTS } from "../../../api/requests";

import TEXTS from '../../../config/texts';
import InfoDrawer from './InfoDrawer';
import ActivationDrawer from './ActivationDrawer';
import SubresellerDrawer from './SubresellerDrawer';

export default function SubResellerSubresellers({ current }) {
    const [selected, setSelected] = useState(null);
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [sort, setSort] = useState(["id", "DESC"]);
    const [search, setSearch] = useState({});

    const [visible, setVisible] = useState(false);
    const [activationType, setActivationType] = useState("");
    const [openInfoDrawer, setOpenInfoDrawer] = useState(false)
    const [actiavtionDrawer, setActivationDrawer] = useState(false);

    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);

    const searchInput = useRef(null);

    const translation = useSelector((state) => state.globalState.translation);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            width: "60px",
            render: (TEXTS, record, index) => index + 1,
        },
        {
            title: `${translation["Name"] || TEXTS["Name"]}`,
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps(searchInput),
        },
        {
            title: `${translation["Email"] || TEXTS["Email"]}`,
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps(searchInput),
        },
        {
            title: `${translation["Credit"] || TEXTS["Credit"]
                }`,
            dataIndex: "total_activations",
            key: "total_activations",
            sorter: true,
        },
        {
            title: `${translation["Activated devices"] || TEXTS["Activated devices"]
                }`,
            dataIndex: "devices",
            key: "devices",
            render: (TEXTS, record, index) => {
                if (record && record.devices) {
                    return record.devices.length;
                }
            },
        },
        {
            title: `${translation["IP address"] || TEXTS["IP address"]}`,
            dataIndex: "ip",
            key: "ip",
            ...getColumnSearchProps(searchInput),
        },
        {
            title: `${translation["Subresellers count"] || TEXTS["Subresellers count"]
                }`,
            dataIndex: "subresellers_count",
            key: "subresellers_count",
            sorter: true,
        },
        {
            title: `${translation["Date"] || TEXTS["Date"]
                }`,
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: true,
            render: (text) => parseFullDate(text)
        },
        {
            title: "",
            key: "operation",
            align: "center",
            width: "2%",
            render: (text, record, index) => {
                return (<div onClick={(e) => e.stopPropagation()}>
                    <TableButtons
                        handleMenuClick={(e) => handleMenuClick(e, record)}
                        buttons={[
                            {
                                key: "edit",
                                text: translation["Edit"] || TEXTS["Edit"],
                                icon: ICONS.PEN,
                            },
                            {
                                key: "disable",
                                text: record.is_disabled ? translation["Enable"] || TEXTS["Enable"] : translation["Disable"] || TEXTS["Disable"],
                                icon: record.is_disabled ? ICONS.LOCK : ICONS.PADLOCK,
                            },
                            {
                                key: "Add Credit",
                                text: translation["Add Credit"] || TEXTS["Add Credit"],
                                icon: <PlusOutlined />,
                            },
                            {
                                key: "Take Credit",
                                text:
                                    translation["Take Credit"] || TEXTS["Take Credit"],
                                icon: <MinusOutlined />,
                            },
                        ]}
                    />
                </div>)
            }
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setSearch(filters);
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    const disableSubreseller = (id) => {
        REQUESTS.SUBRESELLER_DISBALE(
            { id },
            (data) => {
                getSubresellers();
            },
            (err) => { }
        );
    };

    const handleMenuClick = (e, item) => {

        switch (e.key) {
            case "disable":
                confirm(
                    item.is_disabled
                        ? `${translation["Are you sure you want to enable?"] ||
                        TEXTS["Are you sure you want to enable?"]
                        }`
                        : `${translation["Are you sure you want to disable?"] ||
                        TEXTS["Are you sure you want to disable?"]
                        }`,
                    () => disableSubreseller(item.id),
                    translation["OK"] || TEXTS["OK"],
                    translation["Cancel"] || TEXTS["Cancel"]
                );
                break;

            case "edit":
                setVisible(true);
                setSelected(item);
                break;
            case "Add Credit":
                setActivationDrawer(true);
                setSelected(item);
                setActivationType("Add Credit");
                break;
            case "Take Credit":
                setActivationDrawer(true);
                setSelected(item);
                setActivationType("Take Credit");
                break;
            default:
                break;
        }
    };

    const getSubresellers = () => {
        const query = {
            sort: JSON.stringify(sort),
            parent_id: +current.id,
            page: currentPage,
            limit,
            pagination: 1,
        };

        if (!query.search) {
            query.search = {};
        }

        if (search.name) {
            query.search["name"] = search.name[0];
        }

        if (search.ip) {
            query.search["ip"] = search.ip[0];
        }

        if (search.email) {
            query.search["email"] = search.email[0];
        }

        query.search = JSON.stringify(query.search);

        function callback(data) {
            setData(data.rows);
            setTotal(data.count);
            setCurrentPage(data.currentPage);
            setLimit(data.limit);
        }

        REQUESTS.SUBRESELLERS.GET(query, callback);
    }

    useEffect(() => {
        let timeout = setTimeout(() => {
            getSubresellers();
        }, 500);
        return () => clearTimeout(timeout);
    }, [search, sort,currentPage,limit]);

    return (
        <div >
            <Pages title={translation["Subresellers"] || TEXTS["Subresellers"]}>
                <Table
                    rowKey="id"
                    bordered
                    columns={columns}
                    dataSource={data}
                    rowClassName={(record, index) =>
                        data[index].is_disabled ? "is_disabled" : "row"
                    }
                    loading={loading}
                    onChange={handleTableChange}
                    scroll={{ x: "max-content" }}
                    size="small"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                setOpenInfoDrawer(true);
                                setSelected(record);
                            },
                        };
                    }}
                    pagination={{
                        position: ["bottomCenter"],
                        current: currentPage,
                        total: total,
                        pageSize: limit,
                        showSizeChanger: true,
                    }}

                />
                <SubresellerDrawer
                    visible={visible}
                    onClose={() => { setVisible(false); setSelected(null) }}
                    current={selected}
                    getSubresellers={getSubresellers}
                />
                <ActivationDrawer
                    visible={actiavtionDrawer}
                    onClose={() => { setActivationDrawer(false); setSelected(null) }}
                    activationType={activationType}
                    getSubresellers={getSubresellers}
                    current={selected}
                />
                <InfoDrawer visible={openInfoDrawer} current={selected} onClose={() => { setOpenInfoDrawer(false); setSelected(null) }} />
            </Pages>
        </div>
    )
}
