import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Form, Input, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { REQUESTS } from "../../api/requests";

import TEXTS from "../../config/texts";
import country from "../../country.json";

import ResetEmailDrawer from "./ResetEmailDrawer";
import ServerInfoDrawer from "./ServerInfoDrawer";

import classes from "../styles/profileDrawer.module.scss";
import Message from "../../components/message";

const { Option } = Select;

const ProfileDrawer = ({ visible, onClose, getProfile }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const profile = useSelector((state) => state.globalState.profile);
  const translation = useSelector((state) => state.globalState.translation);

  const [loading, setLoading] = useState(false);
  const [resetVisible, setResetVisible] = useState(false);
  const [serverInfoVisible, setServerInfoVisible] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name,
      surname: values.surname,
      phone: values.phone,
      address: values.address,
      company: values.company,
      email: values.email,
      country: values.country,
    };
    // if (profile?.reseller?.is_reseller) {
    //   body["xstream_host"] = values.xstream_host;
    // }
    function callback() {
      setLoading(false);
      getProfile();
      onClose();
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.PROFILE.EDIT(body, callback, errorCallback);
  };

  const onFinishFailed = (error) => {
    setLoading(false);
  };

  const resetShowDrawer = (event) => {
    event.preventDefault();
    setResetVisible(true);
  };

  const serverInfoShowDrawer = (event) => {
    event.preventDefault();
    setServerInfoVisible(true);
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSave = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSave();
    }
  };
  
  useEffect(() => {
    if (visible) {
      const info = profile.reseller;
      if (info) {
        form.resetFields();
        form.setFields([
          { name: "name", value: info.name },
          { name: "surname", value: info.surname },
          { name: "phone", value: info.phone },
          { name: "address", value: info.address },
          { name: "company", value: info.company },
          { name: "email", value: info.email },
          { name: "country", value: info.country },
          // { name: "xstream_host", value: info.xstream_host },
          { name: "registration_number", value: info.registration_number },
        ]);
      }
    } else {
      removeMessage();
    }
  }, [profile, visible]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <>
      <Drawer
        width={windowWidth > 600 ? 600 : "75vw"}
        className={classes["drawer"]}
        title={translation["Edit profile"] || TEXTS["Edit profile"]}
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <Form
          form={form}
          name="profile"
          layout={"vertical"}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError
        >
          <div className={classes["form-input-block"]}>
            <Form.Item
              name="name"
              label={translation["Name"] || TEXTS["Name"]}
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                  whitespace: true,
                },
              ]}
            >
              <Input
                onKeyDown={onInputEnter}
                placeholder={
                  translation["Enter your name"] || TEXTS["Enter your name"]
                }
              />
            </Form.Item>

            <Form.Item
              name="surname"
              label={translation["Surname"] || TEXTS["Surname"]}
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                  whitespace: true,
                },
              ]}
            >
              <Input
                onKeyDown={onInputEnter}
                placeholder={
                  translation["Enter your surname"] ||
                  TEXTS["Enter your surname"]
                }
              />
            </Form.Item>

            <Form.Item
              name="email"
              label={
                <div>
                  {translation["Email"] || TEXTS["Email"]}{" "}
                  <span
                    className={classes["drawer-open"]}
                    onClick={resetShowDrawer}
                  >
                    {translation["Reset"] || TEXTS["Reset"]}
                  </span>
                </div>
              }
            >
              <Input
                disabled={true}
                placeholder={
                  translation["Enter your email"] || TEXTS["Enter your email"]
                }
              />
            </Form.Item>

            <Form.Item
              name="phone"
              label={translation["Phone number"] || TEXTS["Phone number"]}
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                },
              ]}
            >
              <Input
                type="number"
                placeholder={
                  translation["Enter your phone number"] ||
                  TEXTS["Enter your phone number"]
                }
              />
            </Form.Item>

            <Form.Item
              name="registration_number"
              label={
                translation["Registration number"] ||
                TEXTS["Registration number"]
              }
            >
              <Input
                disabled={true}
                placeholder={
                  translation["Enter your registration number"] ||
                  TEXTS["Enter your registration number"]
                }
              />
            </Form.Item>

            <Form.Item
              className={classes["item"]}
              name="country"
              label={translation["Country"] || TEXTS["Country"]}
            >
              <Select className={classes["select"]} showSearch={true}>
                {country.map((item) => {
                  return (
                    <Option key={item.code} value={`${item.name}`}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="address"
              label={translation["Address"] || TEXTS["Address"]}
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                  whitespace: true,
                },
              ]}
            >
              <Input
                onKeyDown={onInputEnter}
                placeholder={
                  translation["Enter your address"] ||
                  TEXTS["Enter your address"]
                }
              />
            </Form.Item>

            <Form.Item
              name="company"
              label={translation["Company"] || TEXTS["Company"]}
              rules={[
                {
                  required: true,
                  message:
                  translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                  whitespace: true,
                },
              ]}
            >
              <Input
                onKeyDown={onInputEnter}
                placeholder={
                  translation["Enter your company name"] ||
                  TEXTS["Enter your company name"]
                }
              />
            </Form.Item>

            {/* {profile?.reseller?.is_reseller && (
              <Form.Item
                name="xstream_host"
                label={translation["Xtream host"] || TEXTS["Xtream host"]}
              >
                <Input
                  placeholder={
                    translation["Enter your Xtream host"] ||
                    TEXTS["Enter your Xtream host"]
                  }
                />
              </Form.Item>
            )} */}
          </div>

          <Message message={message} />

          <Form.Item className={classes["drawer-button"]}>
            <div className={classes['drawer-button']}>
              <Button
                loading={loading}
                type="primary"
                size={"large"}
                icon={<CheckOutlined />}
                onClick={onSave}
              >
                {translation["Save"] || TEXTS["Save"]}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Drawer>

      <ResetEmailDrawer
        visible={resetVisible}
        onClose={() => setResetVisible(false)}
        getProfile={getProfile}
      />

      {/*<ServerInfoDrawer*/}
      {/*    visible={serverInfoVisible}*/}
      {/*    onClose={() => setServerInfoVisible(false)}*/}
      {/*/>*/}
    </>
  );
};

export default ProfileDrawer;
