import axios from "axios";
import CONSTANTS from "../config";

const request = (method, url, body, callback, errorCallback = () => { }, networkError) => {
    let myAxios = axios.create();

    myAxios.defaults.headers.common["Authorization"] = localStorage.getItem(`${CONSTANTS.JWT}`);


    myAxios[method](url, method === "delete" ? { data: body } : body)
        .then((response) => {
            if (!response.data.error) {
                callback(response.data.message);
            } else {

                if (response.data.error &&
                    response.data.code == 401) {

                    localStorage.removeItem(`${CONSTANTS.JWT}`);
                    
                    window.location.href = "/#/login";
                } else {
                    errorCallback(response.data.message);
                }
            }
        })
        .catch((error) => {

            console.log(error, 'req ERROR');

            if (networkError) {
                networkError(error.message);
            }
            if (error.response && error.response.data && error.response.data.message) {
                errorCallback(error.response.data.message);
            }
        });
};

export default request;
