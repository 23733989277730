import { Input, DatePicker } from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const getColumnSearchProps = (ref) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
    return (
      <Input
        allowClear
        ref={ref}
        placeholder="Type text here"
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
      />
    );
  },
  filterIcon: () => {
    return (
      <>
        <SearchOutlined />
      </>
    );
  },

  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => ref.current?.select(), 100);
    }
  },
});

const getColumnDateProps = (onChange) => ({
  filterDropdown: ({}) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <RangePicker
        onChange={(e, dateString) => onChange(dateString)}
        renderExtraFooter={() => "extra footer"}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <CalendarOutlined
      style={{
        color: filtered ? "#1890ff" : undefined,
      }}
    />
  ),
});

const getQueryDate = (date) => {
  if (date && date[0]) {
    let to = new Date(date[1]);
    to.setDate(to.getDate() + 1);

    return JSON.stringify({
      createdAt: {
        from: new Date(date[0]),
        to: new Date(to),
      },
    });
  }

  return null;
};

export { getColumnSearchProps, getColumnDateProps, getQueryDate };
