import CONSTANTS from "../config/index";

const URLS = {
    // a
    APP_INFO:`${CONSTANTS.API_HOST}api/app_info`,
    ACTIVATE: `${CONSTANTS.API_HOST}reseller/activate`,
    ADD_EXISTING_DEVICE: `${CONSTANTS.API_HOST}reseller/add_existing_device`,
    ACTIVATION_CODE: `${CONSTANTS.API_HOST}reseller/activation_code`,
    ACTIVATION_PACKAGES: `${CONSTANTS.API_HOST}reseller/activation_packages`,
    ACTIVATIONS_HISTORY: `${CONSTANTS.API_HOST}reseller/activations_history`,
    // b
    // c
    CAPTCHA_FOR_LOGIN: `${CONSTANTS.API_HOST}api/captcha`,
    // d
    DASHBOARD: `${CONSTANTS.API_HOST}reseller/dashboard`,
    DEVICES: `${CONSTANTS.API_HOST}reseller/devices`,
    // e
    EMAIL: `${CONSTANTS.API_HOST}reseller/email`,
    // f
    FORGOT_PASSWORD: `${CONSTANTS.API_HOST}reseller/email_for_reset`,
    // g
    // h
    // i
    // j
    // k
    // l
    LOGIN: `${CONSTANTS.API_HOST}reseller/login`,
    LANGUAGES: `${CONSTANTS.API_HOST}reseller/languages`,
    // m
    // n
    // o
    // p
    PLAYLIST: `${CONSTANTS.API_HOST}reseller/provider/playlist`,
    PACKAGES: `${CONSTANTS.API_HOST}reseller/packages`,
    PROFILE: `${CONSTANTS.API_HOST}reseller`,
    PLAYLIST: `${CONSTANTS.API_HOST}reseller/playlist`, //TODO

    PAYMENT_SETTINGS: `${CONSTANTS.API_HOST}payment/settings`,

    MAC_VALIDATION: `${CONSTANTS.API_HOST}api/validate_mac`,
    // q
    // r
    RES_CHARTS: `${CONSTANTS.API_HOST}reseller/res_charts`,
    REF_CHARTS: `${CONSTANTS.API_HOST}reseller/ref_charts`,
    REFERRAL_LINK: `${CONSTANTS.API_HOST}reseller/link`,
    RESET_EMAIL: `${CONSTANTS.API_HOST}reseller/change_email`,
    RESET_PASSWORD: `${CONSTANTS.API_HOST}reseller/reset_password`,
    // s
    SUBRESELLERS:`${CONSTANTS.API_HOST}reseller/subreseller`,
    SUBRESELLER_DISBALE:`${CONSTANTS.API_HOST}reseller/disable`,
    SUBRESELLER_ACTIVATION:`${CONSTANTS.API_HOST}reseller/activation`,
    SUBRESELLERS_STATISTICS: `${CONSTANTS.API_HOST}reseller/subresellers_total`,
    SERVER_GET:`${CONSTANTS.API_HOST}reseller/servers`,
    // t
    TRANSLATION: `${CONSTANTS.API_HOST}editor/reseller_translations`,
    // u
    // v
    // w
    WITHDRAWALS: `${CONSTANTS.API_HOST}reseller/withdraw`,
    // x
    // y
    // z
};

export default URLS;
