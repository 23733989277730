import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Checkbox, Drawer, Form, Input, Select } from "antd";
import CONSTANTS from "../../../config/index"
import { CheckOutlined } from "@ant-design/icons";

import { actions } from "../../../store/index";
import TEXTS from "../../../config/texts";
import { REQUESTS } from "../../../api/requests";
import Message from "../../../components/message";

import classes from "../style/getCodeDrawer.module.scss";

const GetCodeDrawer = ({ visible, onClose, mac, closeGetCodeDrawer, removeSearch }) => {
  const translation = useSelector((state) => state.globalState.translation);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const { setProfile } = bindActionCreators(actions, dispatch);
  const appInfo = useSelector((state) => state.globalState.appInfo);
  const [isDisable, setIsDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [packages, setPackages] = useState(null);

  const paymentSettings = useSelector((state) => state.globalState.pymentSettings);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    const body = {
      code: values.code,
      mac,
      // existing_device: true,
      // package_id: values.package_id,
    };

    function callback() {
      setLoading(false);
      closeGetCodeDrawer();
      removeSearch();
      getProfile();
    }


    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.ACTIVATED_DEVICE.ADD_EXISTING_DEVICE(body, callback, errorCallback);
  };

  const onFinishFailed = (error) => {
    setLoading(false);

    if (typeof error === "string") {
      setMessage({
        text: error,
        type: false,
      });
    }
  };

  const getProfile = () => {
    function callback(data) {
      setProfile(data);
    }

    REQUESTS.PROFILE.GET(callback);
  };

  const onValuesChange = (changed, all) => {
    removeMessage();

    if (all.privacy_policy) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (form.getFieldValue("privacy_policy") && event.key === "Enter") {
      onSubmit();
    }
  };
  const getPackages = () => {
    REQUESTS.PACKAGES((data) => setPackages(data.rows));
  };

  useEffect(() => {
    if (!visible) {
      removeMessage();
      setIsDisable(true);
      setLoading(false);
    } else {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    getPackages();
    
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <>
      <Drawer
        width={500}
        className={classes["drawer"]}
        title={translation["Get code"] || TEXTS["Get code"]}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Form
          form={form}
          layout={"vertical"}
          name="add-existing-device"
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <div className={classes["form-input-block"]}>
            <Form.Item
              name="code"
              label={translation["Code from TV"] || TEXTS["Code from TV"]}
              rules={[
                {
                  required: true,
                  message:translation["This field is required!"] || TEXTS["This field is required!"],
                },
              ]}
            >
              <Input
                onKeyDown={onInputEnter}
                placeholder={translation["Enter code"] || TEXTS["Enter code"]}
              />
            </Form.Item>
          </div>

          <div className={classes["add-checkbox-block",translation]}>
            <Form.Item
              className={classes[""]}
              name="privacy_policy"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item>
              <a
                href={CONSTANTS.REACT_APP_WEB_DOMAIN + "#/privacy"}
                target={"_blank"}
              >
                {translation["Privacy policy"] || TEXTS["Privacy policy"]}
              </a>
            </Form.Item>
          </div>

          <Message message={message} />

          <Form.Item className={classes["activate-button"]}>
            <Button
              type="primary"
              disabled={isDisable}
              loading={loading}
              onClick={onSubmit}
              icon={<CheckOutlined />}
            >
              {translation["Save"] || TEXTS["Save"]}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default GetCodeDrawer;
