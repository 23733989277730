import { Button } from "antd";

import CONSTANTS from "../config";

import classes from "./style/stripe.module.scss";

export default function StripeButton({ resellerId, selectedPackage }) {
    const payByStripe = () => {
        // setStripeButtonLoading(true);

        let host = `${CONSTANTS.API_HOST}payment/stripe/checkout?type=reseller_bay_activation&activation_package_id=${selectedPackage.id}&reseller_id=${resellerId}`;

        window.location.href = host;
        // setStripeButtonLoading(false);
    };
    return (
        <Button
            type="primary"
            onClick={payByStripe}
            // loading={stripeButtonLoading}
            className={classes["stripe-button"]}
        >
            Stripe
        </Button>
    );
}
