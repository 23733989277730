const CONSTANTS = {
    NOT_AVAILABLE: "N/A",

    JWT: "JWT",

    API_HOST: process.env.REACT_APP_API_URL || "http://api.setaplayer.com/",

    REACT_APP_WEB_DOMAIN: process.env.REACT_APP_WEB_DOMAIN || "https://www.setaplayer.com/",

    APP_NAME: process.env.REACT_APP_APPLICATION_NAME || "SETAPLAYER ",

    APP_COLOR: "black",
};

export default CONSTANTS;
