import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { Select } from "antd";

import { actions } from "../../store/index";
import { REQUESTS } from "../../api/requests";

import classes from "../styles/languageBlock.module.scss";

const { Option } = Select;

// The LanguageBlock component is used to display a language block to the user. The LanguageBlock component
// contains the Select component from the antd library. The Select component is used to select a language.
// The LanguageBlock component also contains the getLanguages function. The getLanguages function is used to
// get the list of languages from the server. The getTranslation function is also called here.

const LanguageBlock = () => {
    const dispatch = useDispatch();
    const { setTranslation } = bindActionCreators(actions, dispatch);
    const [allTranslations, setAllTranslations] = useState(null)
    const [selected, setSelected] = useState("");
    const [languagesData, setLanguagesData] = useState([]);

    const getLanguages = () => {
        function callback(data) {

            setLanguagesData(data);

            if (localStorage.getItem("LANGUAGE_ID")) {

                let languageId = +localStorage.getItem("LANGUAGE_ID");

                setSelected(languageId);
            } else {
                let isDefault = data.find((item) => item.is_default);

                setSelected(isDefault.id);
            }
        }

        function errorCallback(error) { }

        REQUESTS.LANGUAGES(callback, errorCallback);
    };

    const getAllTranslations = () => {
        function callback(data) {
            const translations = JSON.parse(data.reseller_translations)
            setAllTranslations(translations)

        }

        REQUESTS.TRANSLATION(callback);
    };

    const setSelectedLanguageTranslations = (selectedLanguageId) => {
        let selectedLanguage = null;

        if (languagesData) {
            selectedLanguage = languagesData.find(item => item.id == selectedLanguageId);
            if (allTranslations && selectedLanguage && selectedLanguage.iso_code) {
                if (allTranslations[selectedLanguage.iso_code]) {
                    setTranslation(allTranslations[selectedLanguage.iso_code])
                }
            }
        }
    }

    useEffect(() => {
        if (selected) {
            localStorage.setItem("LANGUAGE_ID", selected);
            setSelectedLanguageTranslations(selected)
        }
    }, [selected])


    useEffect(() => {
        getLanguages();
        getAllTranslations()
    }, []);
    return (
        <>
            <Select
                value={selected}
                // listHeight={135}
                bordered={false}
                suffixIcon={null}
                className={classes["select"]}
                dropdownClassName={classes["dropdown"]}
                onChange={setSelected}
            >
                {languagesData.map((data) => {
                    return (
                        <Option key={data.id} value={data.id}>
                            <img src={`${data.icon}`} alt="" />
                            {/* <span className={classes["iso-code"]}>
                                {data && data.iso_code  &&  data.iso_code.toUpperCase()}
                            </span> */}
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};

export default LanguageBlock;
