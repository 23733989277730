import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";

import store from "./store/store";

import "./App.css";

import "./index.css";

import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

// Our app is wrapped with a provider component that has a store prop. The store prop is the redux store. We have a hash router component that has a
// app component.

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>
);

reportWebVitals();
