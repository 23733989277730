import classes from "./style/message.module.scss";

// The Message component is used to display a message to the user.
// Massage can be either a success or error message.

export default function Message({ message, style }) {
    return (
        <div className={classes[message.text ? "message-open" : "message-close"]}>
            <span className={classes[message.type ? "success-text" : "error-text"]}>
                {message.text}
            </span>
        </div>
    );
}
