import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import TEXTS from "../config/texts";

import classes from "./style/paypalbutton.module.scss";

export default function PaypalButton({
    paymentSettings,
    selectedPackage,
    loading,
    resellerId,
    onClose,
}) {
    const navigate = useNavigate();

    const translation = useSelector((state) => state.globalState.translation);

    const [show, setShow] = useState(false);
    const [isSupport, setIsSupport] = useState(false);

    useEffect(() => {
        if (paymentSettings?.paypal_client_id) {
            axios
                .get(
                    ` https://www.paypal.com/sdk/js?client-id=${paymentSettings.paypal_client_id}&currency=${paymentSettings.currency}
        `
                )
                .then(() => {
                    setShow(true);
                    setIsSupport(true);
                })
                .catch(() => {
                    setShow(true);
                    loading();
                    setIsSupport(false);
                });
        }
    }, [paymentSettings]);

    return (
        <>
            {show &&
                (isSupport ? (
                    <PayPalScriptProvider
                        options={{
                            "client-id": `${paymentSettings.paypal_client_id}`,
                            currency: `${paymentSettings.currency}`,
                        }}
                    >
                        <PayPalButtons
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                currency_code: `${paymentSettings.currency}`,
                                                value: selectedPackage.price,
                                            },

                                            custom_id: JSON.stringify({
                                                t: "rba", //type
                                                ri: resellerId, //reseller_id
                                                api: selectedPackage.id, //activation_package_id
                                            }),
                                        },
                                    ],
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then((details) => {
                                    onClose();
                                    navigate("/dashboard/payment-success");
                                });
                            }}
                            onError={(error) => {
                                onClose();

                                navigate("/dashboard/payment-error");
                            }}
                            onInit={() => {
                                loading();
                            }}
                        />
                    </PayPalScriptProvider>
                ) : (
                    <div className={classes["message"]}>
                        {translation[
                            "Selected currency is not supported by Paypal. Contact to administration"
                        ] ||
                            TEXTS[
                                "Selected currency is not supported by Paypal. Contact to administration"
                            ]}
                    </div>
                ))}
        </>
    );
}
