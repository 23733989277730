import ResetPasswordForm from './components/ResetPasswordForm';

import classes from './index.module.scss';

export default function ResetPasswordPage() {

    return (
        <div className={classes['reset-password-page']}>
            <div className={classes['reset-password-page-illustration-container']}>
                <img loading={'lazy'} src="/assets/images/illustration.png" alt="illustration-img"/>
            </div>
            <div className={classes['reset-password-form-block']}>
                <div className={classes['reset-password-form-block-container']}>
                    <div className={classes['reset-password-container-img']}>
                        <img loading={'lazy'} src="/assets/images/logo.jpg" alt="img"/>
                    </div>

                    <div className={classes['reset-password-form']}>
                        <ResetPasswordForm />
                    </div>
                </div>
            </div>
        </div>
    );
}
