import { useSelector } from "react-redux";
import { Dropdown, Menu, Space } from "antd";

import TEXTS from "../../config/texts";
import classes from "../styles/walletBlock.module.scss";

const WalletBlock = () => {
    const profile = useSelector((state) => state.globalState.profile);
    const translation = useSelector((state) => state.globalState.translation);

    const menu = () => {
        const data = profile.reseller;
        const list = [];

        if (data && data.is_referral) {
            const initialList = [
                {
                    name: `${translation["Earnings wallet"] || TEXTS["Earnings wallet"]}`,
                    quantity: data?.balance,
                },
                {
                    name: `${translation["Total earnings"] || TEXTS["Total earnings"]}`,
                    quantity: data?.referral_earnings_total,
                },
                {
                    name: `${translation["Balance on hold"] || TEXTS["Balance on hold"]}`,
                    quantity: data?.balance_on_hold || 0,
                },
            ];

            if (data.is_reseller) {
                initialList.push({
                    name: `${translation["Credit"] || TEXTS["Credit"]}`,
                    quantity: data?.total_activations,
                });
            }

            list.push(...initialList);
        } else {
            list.push({
                name: `${translation["Credit"] || TEXTS["Credit"]}`,
                quantity: data?.total_activations,
            });
        }

        return (
            <Menu className={classes["menu"]}>
                {list.map((item, index) => {
                    return (
                        <Menu.Item key={index} className={classes["wallet-dropdown"]}>
                            <span className={classes["total"]}>{item.name}</span>
                            <span className={classes["total-number"]}>{item.quantity}</span>
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    };

    return (
        <Space direction="vertical">
            <Space wrap>
                <Dropdown overlay={menu} placement="bottom" trigger="hover">
                    <img
                        className={classes["wallet-img"]}
                        src="/assets/images/icons/wollet.svg"
                        alt=""
                    />
                </Dropdown>
            </Space>
        </Space>
    );
};

export default WalletBlock;
