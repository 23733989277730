import { Navigate } from "react-router-dom";
import CONSTANTS from "../../config/index";

/**
 *  We have a private route component that checks if the user is logged in or not. If the user is not logged in, it redirects to the login page.
 * @returns
 */

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem(CONSTANTS.JWT);

    return token ? children : <Navigate to={"/login"} />;
};

export default PrivateRoute;
