import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { LockOutlined, UserOutlined, LoginOutlined } from "@ant-design/icons";

import CONSTANTS from "../../../config";
import TEXTS from "../../../config/texts";

import { REQUESTS } from "../../../api/requests";

import Message from "../../../components/message";

import classes from "../styles/loginForm.module.scss";

// This component is used to send a request to the server to log in. The user enters his email and password and the server sends a JWT token to the user.
// The component is used in the Login page. For state we use the Ant Design Form component. Form have a his state and we can use it to get the values of the
// form fields. Loading state is used to show the loading animation when the request is sent to the server. Message state is used to show the message to
// the user. The message can be an error or a success message. The message is shown in the Message component. The component uses the useNavigate hook to
// navigate to the dashboard page after the user is logged in. The component uses the REQUESTS.LOGIN function to send the request to the server. Translation
// is used to get the text from the translation file. The component uses the CONSTANTS.JWT to get the name of the JWT token from the constants file.

const LoginForm = ({ active }) => {
  const navigate = useNavigate();
  const [captchaSiteKey, setCaptchaSieKey] = useState(null);
  const [disabled, setDisabled] = useState(true);
  // The translation is used to get the text from the translation file.
  const translation = useSelector((state) => state.globalState.translation);

  // The loading state is used to show the loading animation when the request is sent to the server.
  const [loading, setLoading] = useState(false);

  // The message state is used to show the message to the user. The message can be an error or a success message.
  // The message is shown in the Message component.
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  // The form state is used to get the values of the form fields.
  const [form] = Form.useForm();

  // The onFinish function is called when the form is submitted. The function sends a request to the server to log in. The function uses the REQUESTS.LOGIN
  const onFinish = (values) => {
    setLoading(true);

    const body = {
      email: values.username,
      password: values.password,
    };

    function callback(data) {
      setLoading(false);

      localStorage.setItem(CONSTANTS.JWT, data);

      navigate("/dashboard");
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    // The networkError function is called when the request to the server fails. The function shows the error message to the user.
    function networkError(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.LOGIN(body, callback, errorCallback, networkError);
  };

  // The onFinishFailed function is called when the form is submitted and the form is not valid. The function shows the error message to the user.
  const onFinishFailed = (error) => {
    setLoading(false);

    if (typeof error === "string") {
      setMessage({
        text: error,
        type: false,
      });
    }
  };

  const onValueChange = () => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  // The onClickLogIn function is called when the user clicks on the Log In button. The function removes the message and submits the form.
  const onClickLogIn = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onClickLogIn();
    }
  };

  function onChange(value) {
    REQUESTS.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setDisabled(false);
      },
      (err) => setDisabled(true)
    );
  }

  const getCaptchaSiteKey = () => {
    REQUESTS.CAPTCHA_FOR_LOGIN((data) => {
      setCaptchaSieKey(data);
    });
  };

  useEffect(() => {
    getCaptchaSiteKey();
  }, []);

  return (
    <Form
      form={form}
      name="form-login"
      className="login-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValueChange}
    >
      <div className={classes["form-input-block"]}>
        <Form.Item
          name="username"
          rules={[
            {
              type: "email",
              message:translation["The input is not valid Email!"] ||
              TEXTS["The input is not valid Email!"],
            },
            {
              required: true,
              message:
                translation["This field is required!"] ||
                TEXTS["This field is required!"],
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={translation["Username"] || TEXTS["Username"]}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              type: "password",
              message:
              translation["This field is required!"] ||
              TEXTS["This field is required!"],
            },
            {
              required: true,
              message:
              translation["This field is required!"] ||
              TEXTS["This field is required!"],
            },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={translation["Password"] || TEXTS["Password"]}
            onKeyDown={onInputEnter}
          />
        </Form.Item>
      </div>

      <Form.Item className={classes["form-item"]}>
        <a onClick={active} className="login-form-forgot" href="">
          {translation["Forgot password"] || TEXTS["Forgot password"]}
        </a>
      </Form.Item>

      <Message message={message} />
      <div className={classes["login-form_google-recaptcha"]}>
        {captchaSiteKey && (
          <ReCAPTCHA sitekey={captchaSiteKey} onChange={onChange} />
        )}
      </div>
      <Form.Item>
        <Button
          type="primary"
          size={"large"}
          loading={loading}
          // disabled={captchaSiteKey ? disabled : false}
          onClick={onClickLogIn}
          icon={<LoginOutlined />}
        >
          {translation["Login"] || TEXTS["Login"]}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
